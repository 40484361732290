<template>
  <div class="ml-3 text-white">
    <div>{{ displayName }}</div>

    <div v-if="!disruptionApiData || disruptionApiData.length === 0">
      <HintMessage v-if="showNoDataHint" message="No data matched the selected criteria" type="no-data" />
    </div>

    <div v-else>
      <div class="bar-chart mb-4">
        <Bar :data="barChartData" :options="barChartOptions" />
      </div>
    </div>
  </div>
</template>

<script>
import { Chart as ChartJS, Filler, ArcElement, Tooltip, Legend } from 'chart.js';
import { Bar } from 'vue-chartjs';
import { toZonedTime } from 'date-fns-tz';
import 'chartjs-adapter-date-fns';
import zoomPlugin from 'chartjs-plugin-zoom';
ChartJS.register(Filler, zoomPlugin, ArcElement, Tooltip, Legend);
import { dashboardTemplate } from '@/helper/dashboardTemplate.js';
import HintMessage from '@/components/HintMessage.vue';

export default {
  name: 'DashboardDisruptedUsers',
  components: {
    Bar,
    HintMessage,
  },

  props: {
    startDate: Date,
    endDate: Date,
    siteId: String,
    displayName: String,
    apiData: Object,
  },

  data() {
    return {
      dashboardTemplate,
      totalGuests: this.apiData.content.totalGuests,
      disruptionApiData: null,
      apiDataMode: null,
      showNoDataHint: false,
      barChartData: {
        labels: [],
        datasets: [],
      },
      barChartOptions: {
        responsive: true,
        plugins: {
          legend: {
            display: false,
          },

          tooltip: {
            callbacks: {
              title: (tooltipItems) => {
                const disruption = tooltipItems[0]?.label || '0 disruptions';
                return `${disruption} disruptions`;
              },
              label: (context) => {
                const users = context.raw || 0;
                return `${users} users`;
              },
            },
          },
        },
      },
    };
  },

  watch: {
    combinedProps: {
      handler: 'checkAndFetchData',
      immediate: true,
    },
  },

  computed: {
    combinedProps() {
      return {
        startDate: this.startDate,
        endDate: this.endDate,
        siteId: this.siteId,
      };
    },
  },

  methods: {
    formatTZ(time) {
      return toZonedTime(time, this.$provider.selection.site.timeZone);
    },

    checkAndFetchData() {
      if (this.combinedProps.startDate && this.combinedProps.endDate && this.combinedProps.siteId) {
        this.fetchData();
      }
    },

    async fetchData() {
      try {
        const res = await this.$ynapse.GET('/api/v1/dashboard/disrupted-users', {
          siteId: this.siteId,
          startDate: this.startDate,
          endDate: this.endDate,
        });

        if (!res.data || res.data.length === 0) {
          this.showNoDataHint = true;
          this.disruptionApiData = null;
        } else {
          this.disruptionApiData = res.data;
          this.processData();
        }
      } catch (error) {
        console.error(error);
      }
    },

    processData() {
      const interval = 60000;
      const groupedDisruptions = {};

      this.disruptionApiData.forEach((exp) => {
        const groupedTimestamps = [];
        let prevTime = null;

        exp.timestamps.forEach((timestamp) => {
          const time = new Date(timestamp).getTime();
          if (!prevTime || time - prevTime > interval) {
            groupedTimestamps.push(time);
            prevTime = time;
          }
        });

        groupedDisruptions[exp.experienceId] = groupedTimestamps.length;
      });

      const disruptionCounts = {};
      Object.values(groupedDisruptions).forEach((count) => {
        if (disruptionCounts[count]) {
          disruptionCounts[count]++;
        } else {
          disruptionCounts[count] = 1;
        }
      });

      let labels = Object.keys(disruptionCounts);
      labels.unshift(0);

      let data = Object.values(disruptionCounts);
      const disruptedUsers = data.reduce((pv, cv) => pv + cv, 0);
      const nonDisruptedUsers = this.totalGuests - disruptedUsers;
      data.unshift(nonDisruptedUsers);

      this.barChartData = {
        labels: labels,
        datasets: [
          {
            backgroundColor: '#6CA0DC',
            data,
          },
        ],
      };
    },
  },
};
</script>

<style scoped>
.bar-chart {
  min-width: 480px;
  max-width: 600px;
}
</style>

const dashboardTemplate = {
  'General Info': [
    {
      type: 'table',
      content: {
        headers: ['Guest', 'Groups', 'VIP', 'PWD', 'Tablets', 'Guest Removed'],
        rows: [['#totalGuests#', '#group#', '#vip#', '#pwd#', '#tablets#', '#guestsRemoved#']],
      },
    },
  ],
  'Battery level Tutorial': [
    {
      type: 'pieChart',
      content: {
        showPercentage: true,
        values: {
          '70% ⇧': '#batteryLevelAtTutorialAbove70 / (batteryLevelAtTutorialAbove70 + batteryLevelAtTutorialBelow70 )#',
          '70% ⇩': '#batteryLevelAtTutorialBelow70 / (batteryLevelAtTutorialAbove70 + batteryLevelAtTutorialBelow70 )#',
        },
        colors: ['#6CA0DC', '#F4E04D'],
      },
    },
  ],
  'Environment Temperature': [
    {
      type: 'metric',
      content: {
        metricName: 'Environment.Temperature.Inventory Area',
        displayName: 'Inventory Area Temperature',
        type: 'number',
        unit: '˚C',
        min: 15,
        max: 30,
      },
    },
    {
      type: 'metric',
      content: {
        metricName: 'Environment.Temperature.VR Area',
        displayName: 'VR Area Temperature',
        type: 'number',
        unit: '˚C',
        min: 15,
        max: 30,
      },
    },
  ],
  'Staff Reports': [
    {
      type: 'table',
      content: {
        headers: ['', 'Swaps', 'Percentage of Total Guests: Swaps'],
        rows: [
          ['Bug in the App', '#appSwaps#', '#appSwaps / totalGuests#'],
          ['Low Battery', '#batterySwaps#', '#batterySwaps / totalGuests#'],
          ['Temperature problem', '#temperatureSwaps#', '#temperatureSwaps / totalGuests#'],
          ['Tracking problem', '#trackingSwaps#', '#trackingSwaps / totalGuests#'],
          ['Sound issue', '#soundSwaps#', '#soundSwaps / totalGuests#'],
          ['Cooling fan', '#fanSwaps#', '#fanSwaps / totalGuests#'],
          ['Hand tracking', '#handsSwaps#', '#handsSwaps / totalGuests#'],
          ['Other', '#otherSwaps#', '#otherSwaps / totalGuests#'],
          [
            'ALL',
            '#appSwaps + batterySwaps + temperatureSwaps + trackingSwaps + soundSwaps + fanSwaps + handsSwaps + otherSwaps#',
            '#(appSwaps + batterySwaps + temperatureSwaps + trackingSwaps + soundSwaps + fanSwaps + handsSwaps + otherSwaps) / totalGuests#',
          ],
        ],
      },
    },
    {
      type: 'disruptedUsersBarChart',
      content: {
        displayName: 'Distribution of disruptions per user',
      },
    },
    {
      type: 'pieChart',
      content: {
        showPercentage: true,
        values: {
          'App Swaps':
            '#appSwaps / (appSwaps + batterySwaps + temperatureSwaps + trackingSwaps + soundSwaps + fanSwaps + handsSwaps + otherSwaps)#',
          'Battery Swaps':
            '#batterySwaps / (appSwaps + batterySwaps + temperatureSwaps + trackingSwaps + soundSwaps + fanSwaps + handsSwaps + otherSwaps)#',
          'Temperature Swaps':
            '#temperatureSwaps / (appSwaps + batterySwaps + temperatureSwaps + trackingSwaps + soundSwaps + fanSwaps + handsSwaps + otherSwaps)#',
          'Tracking Swaps':
            '#trackingSwaps / (appSwaps + batterySwaps + temperatureSwaps + trackingSwaps + soundSwaps + fanSwaps + handsSwaps + otherSwaps)#',
          'Sound Swaps':
            '#soundSwaps / (appSwaps + batterySwaps + temperatureSwaps + trackingSwaps + soundSwaps + fanSwaps + handsSwaps + otherSwaps)#',
          'Fan Swaps':
            '#fanSwaps / (appSwaps + batterySwaps + temperatureSwaps + trackingSwaps + soundSwaps + fanSwaps + handsSwaps + otherSwaps)#',
          'Hands Swaps':
            '#handsSwaps / (appSwaps + batterySwaps + temperatureSwaps + trackingSwaps + soundSwaps + fanSwaps + handsSwaps + otherSwaps)#',
          'Other Swaps':
            '#otherSwaps / (appSwaps + batterySwaps + temperatureSwaps + trackingSwaps + soundSwaps + fanSwaps + handsSwaps + otherSwaps)#',
        },
        colors: ['#6CA0DC', '#8FBC8F', '#F4E04D', '#FFA07A', '#DDA0DD', '#98FB98', '#FFE4B5', '#F5A4F9'],
      },
    },
  ],
  'Hardware disruptions': [
    {
      type: 'table',
      content: {
        headers: ['', 'Occurrences', 'Occurrence rate per guest'],
        rows: [
          ['Bluescreens/Tracking Issues', '#blueScreen#', '#blueScreen / totalGuests#'],
          ['App disconnected during experience', '#appDisconnect#', '#appDisconnect / totalGuests#'],
          ['Re-init tracking', '#reInitTracking#', '#reInitTracking / totalGuests#'],
          ['Irregular tracking moves', '#irregularTracking#', '#irregularTracking / totalGuests#'],
          ['Daemon quick-disconnection', '#daemonQuickDisconnection#', '#daemonQuickDisconnection / totalGuests#'],
        ],
      },
    },
    {
      type: 'barChart',
      content: {
        values: {
          blueScreen: '#blueScreen#',
          appDisconnect: '#appDisconnect#',
          reInitTracking: '#reInitTracking#',
          irregularTracking: '#irregularTracking#',
          daemonQuickDisconnection: '#daemonQuickDisconnection#',
        },
        colors: ['#6CA0DC', '#8FBC8F', '#F4E04D', '#FFA07A', '#DDA0DD'],
      },
    },
  ],
  'HMD Health': [
    {
      type: 'deviceTable',
      content: {
        displayName: 'Problematic Devices',
        data: 'problematicHeadsets',
        showDeviceId: true,
        headers: ['Swap Times', 'Swap rate', 'incomplete experience rate'],
        values: ['#swapTimes#', '#swapRate#', '#incompleteExperienceRate#'],
      },
    },
    // {
    //   type: 'deviceTable',
    //   content: {
    //     displayName: 'Unused Devices',
    //     // data: 'unusedDevices',
    //     data: 'deviceTotalSwaps',
    //     showDeviceId: true,
    //     headers: ['Swap Times'],
    //     values: ['#swapCount#'],
    //   },
    // },
  ],
  'EXPERIENCE CONTENT KPI': [
    {
      type: 'table',
      content: {
        headers: [
          '',
          'Phase I (5:47) (20 videos)',
          'Phase II (5:25) (21 videos)',
          'Phase III (5:30) (19 videos)',
          'Phase IV (5:10) (19 videos)',
          'Total',
        ],
        rows: [
          [
            'videos viewed per visitor',
            '#videoViewedPerVisitorPhase1 / totalGuests#',
            '#videoViewedPerVisitorPhase2 / totalGuests#',
            '#videoViewedPerVisitorPhase3 / totalGuests#',
            '#videoViewedPerVisitorPhase4 / totalGuests#',
            '#videoViewedPerVisitorPhase1 / totalGuests + videoViewedPerVisitorPhase2 / totalGuests + videoViewedPerVisitorPhase3 / totalGuests + videoViewedPerVisitorPhase4 / totalGuests#',
          ],
          [
            'Completed videos',
            '#completedVideosPhase1#',
            '#completedVideosPhase2#',
            '#completedVideosPhase3#',
            '#completedVideosPhase4#',
            '#completedVideosPhase1 + completedVideosPhase2 + completedVideosPhase3 + completedVideosPhase4#',
          ],
          ['Available content time', '0:21:11', '0:24:04', '0:20:23', '0:15:46', '1:21:24'],
          [
            'Viewing time per visitor',
            '#viewingTimePerVisitorPhase1 / 86400000#',
            '#viewingTimePerVisitorPhase2 / 86400000#',
            '#viewingTimePerVisitorPhase3 / 86400000#',
            '#viewingTimePerVisitorPhase4 / 86400000#',
            '#(viewingTimePerVisitorPhase1 / 86400000) + (viewingTimePerVisitorPhase2 / 86400000) + (viewingTimePerVisitorPhase3 / 86400000) + (viewingTimePerVisitorPhase4 / 86400000)#',
          ],
        ],
      },
    },
  ],
  'EXPERIENCE CONTENT': [
    {
      type: 'table',
      content: {
        headers: ['TOP 3 - most viewed hotspots'],
        rows: [
          ['#top1MostViewedHotspotName#', '#top1MostViewedHotspot/ totalGuests#'],
          ['#top2MostViewedHotspotName#', '#top2MostViewedHotspot / totalGuests#'],
          ['#top3MostViewedHotspotName#', '#top3MostViewedHotspot / totalGuests#'],
        ],
      },
    },
    {
      type: 'table',
      content: {
        headers: ['TOP 3 - least viewed hotspots'],
        rows: [
          ['#top1LeastViewedHotspotName#', '#top1LeastViewedHotspot / totalGuests#'],
          ['#top2LeastViewedHotspotName#', '#top2LeastViewedHotspot / totalGuests#'],
          ['#top3LeastViewedHotspotName#', '#top3LeastViewedHotspot / totalGuests#'],
        ],
      },
    },
  ],
  Infrastructure: [
    {
      type: 'table',
      content: {
        rows: [
          ['Access point 1 down', '#infrastructureAccessPoint1#'],
          ['Access point 2 down', '#infrastructureAccessPoint2#'],
          ['Access point 3 down', '#infrastructureAccessPoint3#'],
          ['Access point 4 down', '#infrastructureAccessPoint4#'],
          ['Access point 5 down', '#infrastructureAccessPoint5#'],
        ],
      },
    },
  ],
};

module.exports = {
  dashboardTemplate,
};
